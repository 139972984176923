
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import NavigationLayout from "components/layout/NavigationLayout";
import React, { useContext, useEffect, useRef, useState } from "react";
import Loading from "components/Loading";
import { useRouter } from "next/router";
import { Axios } from "api";
import * as Sentry from "@sentry/nextjs";
import { captureException, captureMessage } from "@sentry/nextjs";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";
import styles from "pages/palette/creator/[ccode].module.scss";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TitleBar from "components/layout/TitleBar";
import Image from "next/image";
import DefaultProfileIcon from "images/icons/default_profile.svg";
import Nickname from "components/Nickname";
import CreatorBadgeIcon from "images/icons/creator_badge.svg";
import { Col, Row } from "react-bootstrap";
import SimplePostListCard from "components/cards/SimplePostListCard";
import InstagramIcon from "images/icons/insta_link_black.svg";
import YoutubeIcon from "images/icons/youtube_link_black.svg";
import TiktokIcon from "images/icons/tiktok_link_black.svg";
import NaverBlogIcon from "images/icons/naver_blog_link_black.svg";
import { NewTopTabBar } from "components/TopTabBar";
import { productScrapContextRef } from "context/ProductScrapContext";
import ProductListCard from "components/cards/ProductListCard";
import { postScrapContextRef } from "context/PostScrapContext";
import ErrorIcon from "images/icons/error.svg";
import Head from "next/head";
import { SITE_URL, storeRoute } from "common/const";
import UserContext from "context/AuthContext";
import FullButton from "components/buttons/FullButton";
import Link from "next/link";
import Modal from "components/Modal";
import { NicknameForm, ProfileImageEditor } from "pages/mypage/my-info";
import { useFormik } from "formik";
import * as yup from "yup";
import { KEY_NICKNAME } from "pages/auth/sign-up";
import { KEY_INSTAGRAM_LINK, KEY_INTRODUCTION, KEY_NAVER_BLOG_LINK, KEY_TIKTOK_LINK, KEY_YOUTUBE_LINK, SellerShopInfoForm } from "components/cards/MyInfoCard";
import TextInput from "components/input/TextInput";
import { toast } from "react-toastify";
import PostListContext from "context/PostListContext";
import { addToSellerShop } from "api/addToSellerShop";
import DownloadIcon from "images/icons/download.svg";
import { handleCouponDownload } from "components/modal/MainCouponModal";
import { customAlert, formatDate, getExpiresAtDateFromDMY, numberWithComma } from "common/utils";
import { COUPON_TYPE_PERCENTAGE } from "components/cards/CheckoutCouponCard";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";
import { setCountryCodeInCookieSSR } from "common/i18n";
const KEY_STORE_TAB = 'store';
const KEY_PALETTE_TAB = 'palette';
export const Error = (props) => {
    const { t } = useTranslation('palette-creator-ccode');
    const { title, message, error } = props;
    return (<div className={styles.errorContainer}>
            {error &&
            <>
                    <ErrorIcon className={styles.errorIcon} viewBox="0 0 80 80"/>
                    <span className={styles.errorTitle}>{t('Error.loadingFailed')}</span>
                </>}
            <span className={styles.errorMessage}>{message}</span>
        </div>);
};
const ProfileEditModal = (props) => {
    const { t } = useTranslation('palette-creator-ccode');
    const { isOpen, creator, setCreator } = props;
    const close = props.close || function () { };
    const closeModal = () => {
        close();
    };
    const userContext = useContext(UserContext);
    const user = userContext.user;
    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: Object.assign({}, NicknameForm.initialValue, {
            [KEY_NICKNAME]: creator.nickname
        }, SellerShopInfoForm.initialValues, {
            [KEY_INTRODUCTION]: creator.introduction || '',
            [KEY_INSTAGRAM_LINK]: creator.instagram_link || '',
            [KEY_YOUTUBE_LINK]: creator.youtube_link || '',
            [KEY_TIKTOK_LINK]: creator.tiktok_link || '',
            [KEY_NAVER_BLOG_LINK]: creator.naver_blog_link || ''
        }),
        validationSchema: yup.object(Object.assign({}, NicknameForm.validationSchema, SellerShopInfoForm.validationSchema)),
        onSubmit: async (values) => {
            if (!NicknameForm.preSubmit(values, () => { })) {
                return;
            }
            if (!SellerShopInfoForm.preSubmit(values)) {
                return;
            }
            try {
                setIsLoading(true);
                const data = JSON.parse(JSON.stringify(values));
                const res = await Axios.patch('v1/my-shop/', data);
                if (res.status < 400) {
                    if (user) {
                        userContext.setUser(oldUser => {
                            const newUser = Object.assign({}, oldUser);
                            newUser.nickname = res.data.nickname;
                            return newUser;
                        });
                    }
                    setCreator(res.data);
                    closeModal();
                    toast.info(t('ProfileEditModal.editSuccess'));
                }
                else {
                    alert(res.data.display_message || t('ProfileEditModal.temporaryError'));
                }
            }
            catch (e) {
                captureException(e);
                alert(t('ProfileEditModal.temporaryError'));
            }
            finally {
                setIsLoading(false);
            }
        }
    });
    const scrollRef = useRef(null);
    const { handleSubmit, resetForm } = formik;
    useEffect(() => {
        if (!isOpen) {
            resetForm();
            if (scrollRef.current) {
                scrollRef.current.scrollTo(0, 0);
            }
        }
    }, [isOpen]);
    return (<Modal isOpen={isOpen} close={closeModal} width={'100%'} height={'100%'}>
            <div ref={scrollRef} className={styles.modalScrollContainer}>
                <div className={classNames(utilStyles.sidePadding, utilStyles.topSticky, utilStyles.whiteBackground)}>
                    <TitleBar isClose close={closeModal}>{t('ProfileEditModal.title')}</TitleBar>
                </div>
                <div className={utilStyles.sidePadding}>
                    <div style={{ height: 24 }}/>
                    <ProfileImageEditor horizontal onChange={e => {
            const newCreator = JSON.parse(JSON.stringify(creator));
            newCreator.profile_image_url = e;
            setCreator(newCreator);
        }}/>
                    <div style={{ height: 40 }}/>
                    <span className={styles.profileEditLabel}>{t('ProfileEditModal.link')}</span>
                    <div style={{ marginBottom: 24 }}>
                        <TextInput ellipsis dark readonly={true} value={`https://hemekolab.com/s/${creator.code}`}></TextInput>
                        <div style={{ height: 12 }}/>
                        <TextInput allowEmptyThrottling readonly={isLoading} value={creator.code} disabled/>
                        <span className={styles.profileEditWarn}>{t('ProfileEditModal.linkWarning')}</span>
                    </div>
                    <span className={styles.profileEditLabel}>{t('ProfileEditModal.username')}</span>
                    {isOpen &&
            <NicknameForm formik={formik} isLoading={isLoading}/>}
                    <SellerShopInfoForm formik={formik} isLoading={isLoading}/>
                    <div className={styles.profileEditSubmitButtonContainer}>
                        <FullButton disabled={isLoading} title={t('ProfileEditModal.saveProfile')} fontSize={16} height={48} onClick={handleSubmit}/>
                    </div>
                </div>
            </div>
        </Modal>);
};
const CreatorPage = (props) => {
    const { t } = useTranslation('palette-creator-ccode');
    const _creator = props.creator;
    const _error = props.ssrError;
    const [data, setData] = useState({
        isLoading: false,
        creator: _creator,
        error: _error
    });
    const user = useContext(UserContext).user;
    const { reportedPostIds } = useContext(PostListContext);
    const [isContentLoading, setIsContentLoading] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [storeData, setStoreData] = useState({
        topProducts: null,
        products: null,
        error: false
    });
    const [paletteData, setPaletteData] = useState({
        posts: null,
        error: false
    });
    const router = useRouter();
    useEffect(() => {
        if (!router.isReady)
            return;
        if (!router.query.tab) {
            const creator = data.creator;
            router.replace({
                query: Object.assign({}, router.query, {
                    tab: (creator?.is_seller ?
                        ((!(creator?.has_seller_shop_products) && creator?.has_post) ? KEY_PALETTE_TAB : KEY_STORE_TAB) :
                        KEY_PALETTE_TAB)
                })
            }, undefined, {
                shallow: true,
                scroll: false
            });
        }
    }, [router.isReady, router.query]);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const creator = data.creator;
    const isMySellerShop = creator && user && creator.nickname === user.nickname;
    const fetchStore = async () => {
        try {
            setIsContentLoading(true);
            setStoreData({
                topProducts: null,
                products: null,
                error: false
            });
            const res = await Axios.get(`v1/seller-shop/${data.creator.code}/products/`);
            if (res.status < 400) {
                setStoreData(values => {
                    const newValues = JSON.parse(JSON.stringify(values));
                    // newValues.topProducts = res.data.special_price_products;
                    newValues.products = res.data.products;
                    return newValues;
                });
                productScrapContextRef.current.updateByProductIdsAndScrappedProductIds(res.data.products.map(e => e.id), res.data['scrapped_pids']);
            }
            else {
                setStoreData(values => {
                    const newValues = Object.assign({}, values);
                    newValues.error = res.data.display_message || t('CreatorPage.loadingError');
                    return newValues;
                });
            }
        }
        catch (e) {
            setStoreData(values => {
                const newValues = Object.assign({}, values);
                newValues.error = t('CreatorPage.loadingError');
                return newValues;
            });
        }
        finally {
            setIsContentLoading(false);
        }
    };
    const fetchPalette = async () => {
        try {
            setIsContentLoading(true);
            setPaletteData({
                posts: null,
                error: false
            });
            const res = await Axios.get(`v1/palette/creator/${data.creator.code}/palette/`);
            if (res.status < 400) {
                setPaletteData(values => {
                    const newValues = JSON.parse(JSON.stringify(values));
                    const posts = res.data.posts;
                    newValues.posts = posts.filter(post => !reportedPostIds.has(post.id));
                    return newValues;
                });
                postScrapContextRef.current.updateByPostIdsAndScrappedPostIds(res.data.posts.map(e => e.id), res.data['scrapped_pids']);
            }
            else {
                setPaletteData(values => {
                    const newValues = Object.assign({}, values);
                    newValues.error = res.data.display_message || t('CreatorPage.loadingError');
                    return newValues;
                });
            }
        }
        catch (e) {
            setPaletteData(values => {
                const newValues = Object.assign({}, values);
                newValues.error = t('CreatorPage.loadingError');
                return newValues;
            });
        }
        finally {
            setIsContentLoading(false);
        }
    };
    useEffect(async () => {
        const code = data.creator?.code;
        if (!code)
            return;
        const tab = router.query.tab;
        if (tab === KEY_STORE_TAB) {
            if (data.creator.is_seller) {
                fetchStore();
            }
        }
        else {
            fetchPalette();
        }
        typeof mixpanel !== 'undefined' && mixpanel.track('ViewSellerShop', { ccode: code, tab: tab });
    }, [data.creator?.code, router.query.tab]);
    const tabs = (data.creator?.is_seller ? [
        {
            route: KEY_STORE_TAB,
            title: t('CreatorPage.storeTab'),
            onClick: () => {
                router.replace({ query: Object.assign({}, router.query, { tab: KEY_STORE_TAB }) }, undefined, {
                    shallow: true,
                    scroll: false
                });
            }
        },
        data.creator?.has_post ? {
            route: KEY_PALETTE_TAB,
            title: t('CreatorPage.paletteTab'),
            onClick: () => {
                router.replace({ query: Object.assign({}, router.query, { tab: KEY_PALETTE_TAB }) }, undefined, {
                    shallow: true,
                    scroll: false
                });
            }
        } : null,
    ] : [
        {
            route: KEY_PALETTE_TAB,
            title: t('CreatorPage.paletteTab'),
            onClick: () => {
                router.replace({ query: Object.assign({}, router.query, { tab: KEY_PALETTE_TAB }) }, undefined, {
                    shallow: true,
                    scroll: false
                });
            }
        },
    ]).filter(e => !!e);
    const creatorUrl = creator ? `${SITE_URL}/s/${creator.code}` : '';
    const onEditProfileClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CreatorPageEditProfileButtonClick');
        setIsEditModalOpen(true);
    };
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [deletedProducts, setDeletedProducts] = useState([]);
    const [isCouponLoading, setIsCouponLoading] = useState(false);
    const handleDeleteButtonClick = async (productId) => {
        if (isDeleteLoading) {
            return;
        }
        setIsDeleteLoading(true);
        await addToSellerShop(productId, deletedProducts.includes(productId));
        setDeletedProducts(prevState => {
            if (prevState.includes(productId)) {
                return prevState.filter(id => id !== productId);
            }
            else {
                return [...prevState, productId];
            }
        });
        setIsDeleteLoading(false);
    };
    return (<>
        <Head>
            {creator &&
            <>
                    <title>{t('CreatorPage.title', { nickname: creator.nickname })}</title>
                    <meta name="description" content={creator.introduction || ''} key="description"/>
                    <meta property="og:site_name" content={t('CreatorPage.siteName')} key="ogSiteName"/>
                    <meta property="og:title" content={t('CreatorPage.ogTitle', { nickname: creator.nickname })} key="ogTitle"/>
                    <meta property="og:description" content={creator.introduction || ''} key="ogDescription"/>
                    <meta property="og:image" content="https://d1cnx04b8cgzcv.cloudfront.net/static/hemeko_logo.png" key="ogImage"/>
                    <meta property="og:url" content={creatorUrl} key="ogUrl"/>
                    <link rel="canonical" href={creatorUrl}/>
                </>}
        </Head>
        <div className={classNames(utilStyles.pageContainer, styles.container)}>
            {creator && user && creator.nickname === user.nickname &&
            <ProfileEditModal setCreator={c => {
                    setData(values => {
                        const newValues = JSON.parse(JSON.stringify(values));
                        newValues.creator = c;
                        return newValues;
                    });
                }} creator={creator} isOpen={isEditModalOpen} close={() => setIsEditModalOpen(false)}/>}
            {data.isLoading ?
            <Loading />
            :
                !!creator ?
                    <>
                            <div className={classNames(utilStyles.sidePadding, utilStyles.topSticky, utilStyles.whiteBackground)}>
                                <TitleBar shareLink={creatorUrl} close={() => router.back()} kakaoShareCallback={() => {
                            try {
                                Kakao.Share.sendScrap({
                                    requestUrl: creatorUrl
                                });
                            }
                            catch (e) {
                                Sentry.captureException(e);
                            }
                        }} isBack={true}>
                                    <Nickname value={creator.nickname.toUpperCase()} creatorCode={creator.code}/>
                                </TitleBar>
                            </div>
                            <div className={styles.creatorSection}>
                                <div className={classNames(utilStyles.flexCol, utilStyles.alignItemsCenter)}>
                                    {!!creator.profile_image_url
                            ?
                                <div className={styles.profileImageWrapper}>
                                                <Image unoptimized src={creator.profile_image_url} objectFit="cover" layout="fill"/>
                                            </div>
                            :
                                <DefaultProfileIcon viewBox="0 0 32 32" className={styles.profileImageWrapper}/>}
                                    <div style={{ marginTop: 16 }}>
                                        <Nickname value={creator.nickname} creatorCode={creator.code} className={classNames(styles.contentNickname, creator.is_verified ? utilStyles.verticalMiddle : undefined)}/>
                                        {creator.is_verified &&
                            <CreatorBadgeIcon viewBox="0 0 16 16" className={classNames(utilStyles.verticalMiddle, styles.badge)}/>}
                                    </div>
                                    {creator.introduction &&
                            <div className={styles.introduction}>{creator.introduction}</div>}
                                    {(creator.instagram_link || creator.youtube_link || creator.tiktok_link || creator.naver_blog_link) &&
                            <div className={styles.snsSection}>
                                            {creator.instagram_link &&
                                    <a href={creator.instagram_link} target="_blank" className={styles.snsIconWrapper}>
                                                    <InstagramIcon viewBox="0 0 40 40" className={styles.snsIcon}/>
                                                </a>}
                                            {creator.youtube_link &&
                                    <a href={creator.youtube_link} target="_blank" className={styles.snsIconWrapper}>
                                                    <YoutubeIcon viewBox="0 0 40 40" className={styles.snsIcon}/>
                                                </a>}
                                            {creator.tiktok_link &&
                                    <a href={creator.tiktok_link} target="_blank" className={styles.snsIconWrapper}>
                                                    <TiktokIcon viewBox="0 0 40 40" className={styles.snsIcon}/>
                                                </a>}
                                            {creator.naver_blog_link &&
                                    <a href={creator.naver_blog_link} target="_blank" className={styles.snsIconWrapper}>
                                                    <NaverBlogIcon viewBox="0 0 40 40" className={styles.snsIcon}/>
                                                </a>}
                                        </div>}
                                    {isMySellerShop &&
                            <div className={styles.profileEditButton} onClick={onEditProfileClick}>
                                            {t('CreatorPage.profileEdit')}
                                        </div>}
                                    {!!creator?.molds && creator.molds.slice(0, 1).map((mold) => {
                            return (<div key={`mold-${mold.id}`} className={styles.couponContainer} onClick={() => {
                                    handleCouponDownload(router, mold, false, isCouponLoading, setIsCouponLoading, () => {
                                        typeof mixpanel !== 'undefined' && mixpanel.track('InfluencerCouponDownloadSuccess', {
                                            moldId: mold.id,
                                            code: creator.code
                                        });
                                        fetchStore();
                                        customAlert(t('CreatorPage.couponIssuedAlert'), t('CreatorPage.confirm'), () => { });
                                    }, t);
                                }}>
                                                    <div className={styles.couponContentContainer}>
                                                        <div className={styles.couponInfo}>
                                                            {mold.type === COUPON_TYPE_PERCENTAGE ? `${numberWithComma(mold.amount)}%` : t('CreatorPage.currency', { amount: numberWithComma(mold.amount) })} | {mold.name}
                                                        </div>
                                                        {<div className={styles.couponExpire}>
                                                                {mold.expiration_type === 'fixed_date' ?
                                        t('CreatorPage.couponExpire', { duration: formatDate(mold.expires_at) }) :
                                        t('CreatorPage.couponExpireAfter', { duration: getExpiresAtDateFromDMY(mold.valid_day, mold.valid_month, mold.valid_year) })}
                                                            </div>}
                                                    </div>
                                                    <div className={styles.couponButtonContainer}>
                                                        <DownloadIcon viewBox="0 0 19 18" width={24} height={24}/>
                                                    </div>
                                                    <div className={styles.couponCutter}>
                                                        <div /><div /><div /><div /><div /><div />
                                                    </div>
                                                </div>);
                        })}
                                </div>
                            </div>
                            {isMobile && <div className={utilStyles.mobileBorder} style={{ marginTop: 24 }}/>}
                            <div className={styles.contentSection}>
                                <NewTopTabBar activeRoute={router.query.tab} smallText tabs={tabs}/>
                                {isContentLoading ?
                            <Loading style={{ marginTop: 60 }}/>
                            :
                                (router.query.tab === KEY_STORE_TAB && data.creator?.is_seller) ?
                                    ((storeData.topProducts?.length > 0 || storeData.products?.length > 0) ?
                                        <div>
                                                        {storeData.products && storeData.products.length > 0 &&
                                                <div className={styles.postProductSection}>
                                                                <div className={styles.productsWrapper}>
                                                                    <Row>
                                                                        {storeData.products.map((e) => {
                                                        return (<Col key={e.id} xs={isLarge ? 3 : 6} className={styles.col}>
                                                                                        <div className={styles.storeCardWrapper}>
                                                                                            <ProductListCard creatorCode={creator.code} product={e} referralInfo={[creator.referral_code, null]} onClick={() => { mixpanel.track('SelectProductOnSellerShop', { ccode: creator.referral_code, product_id: e.id }); }} textPaddingType="medium"/>
                                                                                            {isMySellerShop &&
                                                                <div onClick={() => handleDeleteButtonClick(e.id)} className={styles.deleteButton}>
                                                                                                    {!deletedProducts.includes(e.id) ? t('CreatorPage.deleteFromShop') : t('CreatorPage.addToMyShop')}
                                                                                                </div>}
                                                                                        </div>
                                                                                    </Col>);
                                                    })}
                                                                    </Row>
                                                                </div>
                                                            </div>}
                                                    </div>
                                        :
                                            <>
                                                        {!storeData.error && user?.code && user?.code === data.creator.code ?
                                                    <div className={classNames(utilStyles.sidePadding, utilStyles.alignItemsCenter, utilStyles.flexCol)}>
                                                                    <span className={styles.addText} style={{ marginTop: 40 }}>{t('CreatorPage.noProducts')}</span>
                                                                    <span className={styles.addText} style={{ marginTop: 6, marginBottom: 24 }}>
                                                                        <Trans i18nKey="palette-creator-ccode:CreatorPage.addProductsGuide" components={[<span className={classNames(utilStyles.brandColorText, utilStyles.bold)}/>]}/>
                                                                    </span>
                                                                    <Link href={storeRoute}>
                                                                        <a className={utilStyles.fullWidth} onClick={() => { mixpanel.track('GoToStoreForSelection'); }}>
                                                                            <FullButton title={t('CreatorPage.goToStore')} fontSize={16} height={44}/>
                                                                        </a>
                                                                    </Link>
                                                                </div>
                                                    :
                                                        <Error error={!!storeData.error} message={storeData.error || t('CreatorPage.noRegisteredProducts')}/>}
                                                    </>)
                                    :
                                        (paletteData.posts && paletteData.posts.length !== 0 ?
                                            <div className={styles.paletteWrapper}>
                                                        <Row>
                                                            {paletteData.posts.map((e) => {
                                                    return (<Col key={e.id} xs={isLarge ? 3 : 6}>
                                                                            <div className={styles.paletteCardWrapper}>
                                                                                <SimplePostListCard post={e}/>
                                                                            </div>
                                                                        </Col>);
                                                })}
                                                        </Row>
                                                    </div>
                                            :
                                                <Error error={!!paletteData.error} message={paletteData.error || t('CreatorPage.noRegisteredPosts')}/>)}
                            </div>
                        </>
                    :
                        <div></div>}
        </div>
        </>);
};
CreatorPage.getLayout = NavigationLayout;
async function getServerSideProps(context) {
    setCountryCodeInCookieSSR(context);
    let creator = null;
    let error = null;
    const creatorCode = context.params.ccode;
    try {
        const res = await Axios.get(`/v1/palette/creator/${creatorCode}/`);
        if (res.status < 400) {
            creator = res.data;
        }
        else {
            error = res.data.display_message || t('CreatorPage.loadingError');
            if (res.status !== 404) {
                captureMessage(JSON.stringify(res.data));
            }
        }
    }
    catch (e) {
        captureException(e);
        error = t('CreatorPage.loadingError');
    }
    return {
        props: {
            creator: creator,
            ssrError: error
        }
    };
}
export default CreatorPage;

    async function __Next_Translate__getServerSideProps__19334632cbf__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/palette/creator/[ccode]',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19334632cbf__ as getServerSideProps }
  